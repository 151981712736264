import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { FC, useEffect } from 'react';
import serviceDog from './images/servicedog-min.jpg';
import training1 from './images/training1-min.jpg';

export const Home: FC = () => {
  const isMobile = useMediaQuery('(max-width:450px)');
  const imgSize = 30;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container maxWidth="lg">
      <Box sx={{ bgcolor: '#cfe8fc', minHeight: '100vh' }}>
        <Grid container justifyContent="space-between"></Grid>
        <Grid item sx={{ overflow: 'hidden', height: `${isMobile ? imgSize / 2 : imgSize}rem` }}>
          {/*style={{ position: 'absolute', zIndex: '-1' }} */}
          <img style={isMobile ? { width: '50rem' } : {}} src={training1} alt="Well-trained dog leaping" />
        </Grid>
        <Grid item sx={{ padding: '2rem' }}>
          <Typography sx={{ textAlign: 'center', margin: '3rem 1rem' }} component="p" variant="h3">
            Welcome to Meadows Animal Training
          </Typography>
          <Typography
            sx={{ fontSize: isMobile ? '1rem' : '1.5rem', lineHeight: isMobile ? '2rem' : '3rem', fontWeight: 200 }}
          >
            <br />
            <br />
            Here at Meadows Animal Training, we specialize in helping you and your pet achieve your goals. Whether
            that's months of Specialized Task Training for your Service Animal, or simply helping your furry friend be
            their best self, we will help you get there.
          </Typography>
        </Grid>
        <Grid item sx={{ overflow: 'hidden', height: `${isMobile ? imgSize / 2 : imgSize}rem` }}>
          {/*style={{ position: 'absolute', zIndex: '-1' }} */}
          <img style={{ height: 'auto', width: '100%' }} src={serviceDog} alt="Doggo giving a high five" />
        </Grid>
        <Grid item sx={{ padding: '2rem' }}>
          <Typography
            sx={{ fontSize: isMobile ? '1rem' : '1.5rem', lineHeight: isMobile ? '2rem' : '3rem', fontWeight: 200 }}
          >
            We're ready to put our many years of experience to work to determine the best course to achieving your goals
            with your pet.
            <br />
            <br />
            Contact us at <a href="mailto:meadowsanimaltraining@gmail.com">meadowsanimaltraining@gmail.com</a> or by
            phone at <a href="tel:+19705896719">+1 (970) 589-6719</a>
          </Typography>
        </Grid>
      </Box>
    </Container>
  );
};

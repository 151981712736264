import { Box, Button, Container, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import FbIcon from '@mui/icons-material/Facebook';
import InstaIcon from '@mui/icons-material/Instagram';
import { Outlet, useNavigate } from 'react-router-dom';
import meadowsLogo from './images/meadowslogo-min.png';

export const Header: FC = () => {
  const shouldHideContactText = useMediaQuery('(min-width:850px)');
  const shouldShowHamburger = useMediaQuery('(max-width:750px)');
  const isMobile = useMediaQuery('(max-width:450px)');

  const navigate = useNavigate();
  const imgSize = shouldShowHamburger ? 3 : 7;

  return (
    <>
      <Box sx={{ position: 'fixed', minWidth: '100%', minHeight: '7rem', background: '#508fbf' }}>
        <Grid
          container
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ minWidth: '100%', maxWidth: '100%', minHeight: '100%', height: 'auto', padding: '0 4rem' }}
        >
          <Grid item spacing={1}>
            <Stack direction="row" spacing={1}>
              <a href="tel:+19705896719">
                <Grid container item width="auto">
                  <PhoneIcon sx={{ fill: 'white', height: '1.5rem', padding: '0 .5rem' }} />
                  {shouldHideContactText ? (
                    <Typography component="p" variant="caption">
                      +1 (970) 589-6719
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
              </a>
              <a href="mailto:meadowsanimaltraining@gmail.com">
                <Grid container item width="auto">
                  <MailIcon sx={{ fill: 'white', height: '1.5rem', padding: '0 .5rem' }} />
                  {shouldHideContactText ? (
                    <Typography component="p" variant="caption">
                      meadowsanimaltraining@gmail.com
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
              </a>
              <Grid container item width="auto">
                <a href="https://www.facebook.com/Meadows-Animal-Training-1003336777138281">
                  <FbIcon sx={{ fill: 'white', height: '1.5rem', padding: '0 .5rem' }} />
                </a>
              </Grid>
              <Grid container item width="auto" height="auto">
                <a href="https://www.instagram.com/meadowsanimaltraining54/">
                  <InstaIcon sx={{ fill: 'white', height: '1.5rem', padding: '0 .5rem' }} />
                </a>
              </Grid>
            </Stack>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            flexDirection="row"
            item
            sx={{ width: '100%', height: 'auto' }}
          >
            <Grid item>
              <img
                style={{ padding: '1rem', height: `${imgSize}rem`, width: `${imgSize}rem` }}
                onClick={() => navigate('/')}
                alt="Meadows Animal Training Logo with Dog Jumping"
                src={meadowsLogo}
              />
            </Grid>
            <Grid item padding={isMobile ? '0' : '1.5rem 0 0 0'}>
              <Stack direction="row" sx={{ maxHeight: '5rem' }} divider={<Divider orientation="vertical" flexItem />}>
                <Button
                  onClick={() => navigate('/training')}
                  sx={{ padding: shouldShowHamburger ? '.25rem' : '2rem', color: 'inherit' }}
                  variant="text"
                >
                  Training
                </Button>
                <Button
                  onClick={() => navigate('/mission')}
                  sx={{ padding: shouldShowHamburger ? '.25rem' : '2rem', color: 'inherit' }}
                  variant="text"
                >
                  Our Mission
                </Button>
                <Button
                  onClick={() => navigate('/contact')}
                  sx={{ padding: shouldShowHamburger ? '.25rem' : '2rem', color: 'inherit' }}
                  variant="text"
                >
                  Contact Us
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Container sx={{ paddingTop: `${imgSize + (isMobile ? 7 : 3.8)}rem`, height: '100%', overflow: 'scroll' }}>
        <Outlet />
      </Container>
    </>
  );
};

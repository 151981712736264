import { Box, Container, Grid, List, ListItem, Typography, useMediaQuery } from '@mui/material';
import { FC, useEffect } from 'react';
import chillin from './images/chillin-min.jpg';

export const Contact: FC = () => {
  const isMobile = useMediaQuery('(max-width:450px)');
  const imgSize = 30;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container maxWidth="lg">
      <Box sx={{ bgcolor: '#cfe8fc', minHeight: '100vh' }}>
        <Grid
          item
          sx={{
            overflow: 'hidden',
            height: `${isMobile ? imgSize / 1.5 : imgSize}rem`,
          }}
        >
          {/*style={{ position: 'absolute', zIndex: '-1' }} */}
          <img style={{ width: '100%' }} src={chillin} alt="Doggo chillin'" />
        </Grid>
        <Grid item sx={{ padding: '2rem' }}>
          <Typography sx={{ textAlign: 'center', margin: '3rem 1rem' }} component="p" variant="h3">
            Contact us
          </Typography>
          <Typography sx={{ fontSize: isMobile ? '1rem' : '1.5rem', fontWeight: 200 }}>
            Lead Trainer: Mariah Kollasch
            <List>
              <ListItem>
                Email:{' '}
                <a style={{ padding: '1rem', wordBreak: 'break-all' }} href="mailto:meadowsanimaltraining@gmail.com">
                  meadowsanimaltraining@gmail.com
                </a>{' '}
              </ListItem>
              <ListItem>
                Phone:{' '}
                <a style={{ padding: '1rem' }} href="tel:+19705896719">
                  +1 (970) 589-6719
                </a>
              </ListItem>
            </List>
          </Typography>
        </Grid>
        <Grid item sx={{ padding: '2rem' }}></Grid>
      </Box>
    </Container>
  );
};

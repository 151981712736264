import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { FC, useEffect } from 'react';
import jump from './images/jump-min.jpg';

export const Mission: FC = () => {
  const isMobile = useMediaQuery('(max-width:450px)');
  const imgSize = 30;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container maxWidth="lg">
      <Box sx={{ bgcolor: '#cfe8fc', minHeight: '100vh' }}>
        <Grid
          item
          sx={{
            overflow: 'hidden',
            height: `${isMobile ? imgSize / 1.5 : imgSize}rem`,
          }}
        >
          {/*style={{ position: 'absolute', zIndex: '-1' }} */}
          <img style={{ width: '100%' }} src={jump} alt="Doggo jumping" />
        </Grid>
        <Grid item sx={{ padding: '2rem' }}>
          <Typography sx={{ textAlign: 'center', margin: '3rem 1rem' }} component="p" variant="h3">
            Our Mission
          </Typography>
          <Typography
            sx={{ fontSize: isMobile ? '1rem' : '1.5rem', lineHeight: isMobile ? '2rem' : '3rem', fontWeight: 200 }}
          >
            To provided skilled, devoted, and passionate training for animals to help them be the best they can be,
            whether that's a Task Trained Service Animal, or a well-mannered pet. Our mission is your goal.
          </Typography>
        </Grid>
        <Grid item sx={{ padding: '2rem' }}>
          <Typography
            sx={{ fontSize: isMobile ? '1rem' : '1.5rem', lineHeight: isMobile ? '2rem' : '3rem', fontWeight: 200 }}
          >
            Contact us at <a href="mailto:meadowsanimaltraining@gmail.com">meadowsanimaltraining@gmail.com</a> or by
            phone at <a href="tel:+19705896719">+1 (970) 589-6719</a>
          </Typography>
        </Grid>
      </Box>
    </Container>
  );
};

import { Box, Container, Grid, List, ListItem, Typography, useMediaQuery } from '@mui/material';
import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import onDuty from './images/onduty-min.jpg';
import puppy from './images/puppy-min.jpg';

export const Training: FC = () => {
  const isMobile = useMediaQuery('(max-width:450px)');
  const imgSize = 30;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container maxWidth="lg">
      <Box sx={{ bgcolor: '#cfe8fc', minHeight: '100vh' }}>
        <Grid container justifyContent="space-between"></Grid>
        <Grid item sx={{ overflow: 'hidden', height: `${isMobile ? imgSize / 1.5 : imgSize}rem` }}>
          {/*style={{ position: 'absolute', zIndex: '-1' }} */}
          <img
            style={isMobile ? { width: '120%' } : { width: '100%' }}
            src={onDuty}
            alt="Service Doggo Looking Regal"
          />
        </Grid>
        <Grid item sx={{ padding: '2rem' }}>
          <Typography sx={{ textAlign: 'center', margin: '3rem 1rem' }} component="p" variant="h3">
            Training
          </Typography>
          <Typography
            sx={{ fontSize: isMobile ? '1rem' : '1.5rem', lineHeight: isMobile ? '2rem' : '3rem', fontWeight: 200 }}
          >
            Here at Meadows Animal Training, our passion is your mission.
            <br />
            We are able to do everything from a Service Dog's Specialized Task Training to our Puppy Good Citizen
            Beginner's Course. <Link to="contact">Contact us</Link> to find out which of our many training options is
            right for you!
          </Typography>
        </Grid>
        <Grid item sx={{ overflow: 'hidden', height: `${isMobile ? imgSize / 2 : imgSize}rem` }}>
          {/*style={{ position: 'absolute', zIndex: '-1' }} */}
          <img style={{ height: 'auto', width: '100%' }} src={puppy} alt="Puppy looking over it's shoulder" />
        </Grid>
        <Grid item sx={{ padding: isMobile ? '0' : '2rem' }}>
          <Typography
            sx={{ fontSize: isMobile ? '1rem' : '1.5rem', lineHeight: isMobile ? '2rem' : '3rem', fontWeight: 200 }}
          >
            Services generally on offer (contact us for availability):
            <List>
              <ListItem>Canine Good Citizen</ListItem>
              <ListItem>Puppy Good Citizen Beginner's Course</ListItem>
              <ListItem>Agility/Rally Training</ListItem>
              <ListItem>Specialized Task Training for Service Dogs</ListItem>
            </List>
            <Typography component="p" variant="caption">
              <i>
                Please note that not every animal is able to be Task Trained as a Service Animal. We will help you
                achieve the best possible outcomes for your animal, which may not be Specialized Task Training.
              </i>
            </Typography>
            <br />
            <br />
            Contact us at <a href="mailto:meadowsanimaltraining@gmail.com">meadowsanimaltraining@gmail.com</a> or by
            phone at <a href="tel:+19705896719">+1 (970) 589-6719</a>
          </Typography>
        </Grid>
      </Box>
    </Container>
  );
};

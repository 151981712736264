import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { Header } from './Header';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Home } from './Home';
import { Training } from './Training';
import { Mission } from './Mission';
import { Contact } from './Contact';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Open sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider
        router={createBrowserRouter([
          {
            path: '/*',
            id: 'root',
            element: <Header />,
            children: [
              {
                path: '',
                element: <Home />,
              },
              {
                path: 'training',
                element: <Training />,
              },
              {
                path: 'mission',
                element: <Mission />,
              },
              {
                path: 'contact',
                element: <Contact />,
              },
            ],
          },
        ])}
      />
    </ThemeProvider>
  );
}

export default App;
